(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name wbOrder.controller:CompanyBasicCtrl
   *
   * @description
   *
   */
  angular
    .module('wbOrder')
    .controller('CompanyBasicCtrl', CompanyBasicCtrl);

  function CompanyBasicCtrl(company, $scope, Company, _, $analytics, $translate, Cart, backendUnreachable) {
    var vm = this
      , analyticsSent = new Date();

    vm.companyBasicForm = {};
    vm.theCompany = (!_.isEmpty(company)) ? company : null;
    vm.company = company;

    vm.submit = function submit() {
      $scope.$emit('order.next', 'typeSelector', 'companyBasic');
      Cart.saveInitialData(vm.theCompany);
    };

    vm.isBackendUnreachable = function isBackendUnreachable() {
      return backendUnreachable;
    };

    vm.getCompaniesByName = function getCompaniesByName(text) {
      return Company.query({name: text}).$promise;
    };

    vm.getCompaniesByTaxNum = function getCompaniesByTaxNum(text) {
      return Company.query({vatNumber: text}).$promise;
    };

    vm.updateCompany = function updateCompany(selectedCompany) {
      company = _.merge(company, selectedCompany);

      if (new Date(analyticsSent.getTime() + 500) < new Date()) {
        $analytics.eventTrack($translate.instant('order.steps.analytics.companyBasic.anonymous') + ' - ' + $translate.instant('order.steps.analytics.companySelected'), {
          category: $translate.instant('order.steps.analytics.registration'),
          action: $translate.instant('order.steps.analytics.companyBasic.anonymous') + ' - ' + $translate.instant('order.steps.analytics.companySelected'),
          label: company.vatNumber
        });

        analyticsSent = new Date();
      }
    };
  }
}());
